import { http } from '../utils/http';
const path = process.env.VUE_APP_PATH + '/resource/thirdparty/ocr';

export const apiOcrBankCard = async data => {
  return await http['post'](`${path}/bankCardOcr`, data, false);
};

export const apiOcrDriverLicences = async data => {
  return await http['post'](`${path}/driverLicense`, data, false);
};

export const apiOcrIdCard = async data => {
  return await http['post'](`${path}/idCard`, data, false);
};

export const apiOcrTransportationLicense = async data => {
  return await http['post'](`${path}/transportationLicense`, data, false);
};

export const apiOcrVehicleLicense = async data => {
  return await http['post'](`${path}/vehicleLicense`, data, false);
};

export const apiOcrQualification = async data => {
  return await http['post'](`${path}/qualificationCertificate`, data, false);
};
